import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModTwoImgCols from "../../../components/Chronology/Modules/ModTwoImgCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ModAnimation from "../../../components/Chronology/Modules/ModAnimation";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDatafactCountry from "../../../components/Chronology/Modules/ModDatafactCountry";
import ModDatafactCCAA from "../../../components/Chronology/Modules/ModDatafactCCAA";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModBuscandoDatos from "../../../components/Chronology/Modules/ModBuscandoDatos";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModReport from "../../../components/Chronology/Modules/ModReport";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import { StrongWhite, StrongGold } from "../../../components/Textstyles/Styles";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";
import ModVideo from "../../../components/Chronology/Modules/ModVideo";
import ModFootnote from "../../../components/Chronology/Modules/ModFootnote";
import ModDataEs from "../../../components/Chronology/Modules/ModDataEs";

export const frontmatter = {
  title: "Día 105",
  week: "Semana 15",
  day: "26",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-26",
  path: "/cronologia/semana-15#dia-26-jun/",
};

const Day105 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
        <ModDailyHighlight>
          Suecia presenta la mayor incidencia acumulada (14d) de Europa.
        </ModDailyHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en{" "}
          <strong>España</strong> 1.758 casos mediante pruebas PCR. En ese mismo
          periodo, 147 personas han requerido hospitalización (8,4% de los
          diagnósticos), de los que 12 han sido ingresos en la UCI, y se han
          producido 11 fallecimientos.
        </ModText>
        <ModCovidHighlight>
          Rebrote en Málaga, que suma 90 nuevos positivos
        </ModCovidHighlight>
        <ModCovidHighlight>
          Rebrote detectado en Santander, con 13 contagios confirmados en un
          mismo edificio
        </ModCovidHighlight>

        <ModText>
          La <strong>Dirección General de la Marina Mercante</strong> extiende
          la prohibición a la entrada de cruceros en puertos españoles, y estará
          en vigor hasta que se de por finalizada la crisis sanitaria.
        </ModText>
        <ModImage
          src="/images/svg/07_abr_retorno_turistas.svg"
          alt="atraque cruceros internacionales prohibidos "
        />

        <ModBuscandoDatos num="random" />
      </ContentRight>
    </LayoutDay>
  );
};
export default Day105;
